import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FiUserPlus } from 'react-icons/fi';

export default function Creator({ title, description }) {
    const [creators, setCreators] = useState([]);

    useEffect(() => {
        const fetchCreatorsWithNFTs = async () => {
            try {
                // Получаем все доступные NFT на рынке
                const nftResponse = await axios.get('https://nifty-art.com/api/nfts/market');
                const nfts = nftResponse.data;

                // Собираем уникальные ownerId из NFT
                const uniqueOwners = Array.from(new Set(nfts.map(nft => nft.owner)));

                // Получаем данные о владельцах NFT
                const ownersData = await Promise.all(
                    uniqueOwners.map(async (ownerId) => {
                        try {
                            const userResponse = await axios.get(`https://nifty-art.com/api/users/change/${ownerId}`);
                            return userResponse.data; // данные владельца
                        } catch (error) {
                            console.error(`Error fetching data for owner ${ownerId}:`, error);
                            return null; // Если ошибка, возвращаем null для пропуска
                        }
                    })
                );

                // Фильтруем только успешные ответы
                const validCreators = ownersData.filter(owner => owner !== null);
                setCreators(validCreators);
            } catch (error) {
                console.error("Error fetching creators with NFTs:", error);
            }
        };

        fetchCreatorsWithNFTs();
    }, []);

    return (
        <div className="container md:mt-24 mt-16">
            <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">{title}</h3>
                <p className="text-slate-400 max-w-xl mx-auto">{description}</p>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">
                {
                    creators.map((creator, index) => (
                        <div key={index} className="flex justify-between items-center p-3 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800">
                            <div className="flex items-center">
                                <div className="relative inline-block">
                                    <img src={creator?.avatar || '/assets/images/avatar/2.jpg'} className="h-16 rounded-md" alt={creator.username} />
                                    <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                </div>

                                <div className="ms-3">
                                    <Link to={`/creator-profile/${creator.username}`} className="font-semibold block hover:text-violet-600">{creator.firstName}{creator.lastName}</Link>
                                    <span className="text-slate-400 text-sm block mt-0.5">{creator.username}</span>
                                </div>
                            </div>

                            <Link to="#" className="btn btn-icon rounded-full bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white">
                                <FiUserPlus className='text-[20px]'/>
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
