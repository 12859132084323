import React, { useEffect, useState } from 'react';
import TinySlider from "tiny-slider-react";
import '../../node_modules/tiny-slider/dist/tiny-slider.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MdOutlineArrowForward } from 'react-icons/md';

const settings = {
    lazyload: true,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
    nav: false,
    speed: 400,
    gutter: 0,
    responsive: {
        1025: {
            items: 4
        },
        992: {
            items: 3
        },
        767: {
            items: 2
        },
        320: {
            items: 1
        },
    },
};

export default function Collections() {
    const [nfts, setNfts] = useState([]);
    const [owners, setOwners] = useState({});

    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                // Fetch NFT data
                const nftResponse = await axios.get('https://nifty-art.com/api/nfts/market');
                const nftData = nftResponse.data;

                setNfts(nftData);

                // Get unique owner IDs from NFT data
                const uniqueOwnerIds = [...new Set(nftData.map(nft => nft.owner))];

                // Fetch owner data for each unique ownerId
                const ownerDataPromises = uniqueOwnerIds.map(async (ownerId) => {
                    const ownerResponse = await axios.get(`https://nifty-art.com/api/users/change/${ownerId}`);
                    return { ownerId, ...ownerResponse.data };
                });

                // Resolve all owner data promises
                const ownerData = await Promise.all(ownerDataPromises);

                // Map owners by ID for easy lookup
                const ownerDataMap = ownerData.reduce((acc, owner) => {
                    acc[owner.ownerId] = owner;
                    return acc;
                }, {});

                setOwners(ownerDataMap);

            } catch (error) {
                console.error("Error fetching NFTs or owners:", error);
            }
        };

        fetchNFTs();
    }, []);

    return (
        <>
            <div className="container md:mt-36 mt-24">
                <div className="md:flex justify-between items-center">
                    <div className="md:w-10/12 md:text-start text-center">
                        <h3 className="md:text-[30px] text-[26px] font-semibold">Top Collections</h3>
                    </div>
                    <div className="md:w-2/12 text-end md:block hidden">
                        <Link to="/collections" className="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">See More <MdOutlineArrowForward className="text-sm ms-1" /></Link>
                    </div>
                </div>

                <div className="grid relative grid-cols-1 mt-10">
                    <div className="tiny-four-icon-item">
                        <TinySlider settings={settings}>
                            {
                                nfts.map((nft, index) => {
                                    const owner = owners[nft.owner];
                                    return (
                                        <div className="tiny-slide" key={index}>
                                            <div className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-lg p-3 shadow dark:shadow-gray-800 ease-in-out duration-500 m-2">
                                                <img
                                                    src={nft.image.startsWith('/uploads/nfts/')
                                                        ? `https://nifty-art.com${nft.image}`
                                                        : nft.image}
                                                    className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500"
                                                    alt={nft.name}
                                                />

                                                <div className="relative p-4 -mt-14">
                                                    <div className="relative inline-block">
                                                        <img src={owner?.avatar || '/assets/images/avatar/2.jpg'} className="h-16 rounded-md shadow-md dark:shadow-gray-800" alt={owner?.username} />
                                                        <i className="mdi mdi-check-decagram text-emerald-600 text-2xl absolute -bottom-3 -end-2"></i>
                                                    </div>
                                                    <div className="my-3">
                                                        <Link to={`/item-detail/${owner?.username}/${nft._id}`} className="font-semibold hover:text-violet-600">{nft.name}</Link>
                                                    </div>
                                                    <div className="mt-3">
                                                        <Link to={`/item-detail/${nft._id}`} className="font-semibold block text-[18px] hover:text-violet-600">{nft.title}</Link>
                                                        <span className="text-slate-400 mt-1 text-sm"><span className="italic">by</span> <Link to={`/creator-profile/${owner?.username || 'Unknown'}`} className="text-violet-600 font-medium">{owner?.username || 'Unknown'}</Link></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </TinySlider>
                    </div>
                </div>

                <div className="grid grid-cols-1 mt-6 md:hidden">
                    <div className="text-center">
                        <Link to="/collections" className="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">See More <MdOutlineArrowForward className="text-sm ms-1" /></Link>
                    </div>
                </div>
            </div>
        </>
    );
}
