import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


function isTokenExpired() {
    const token = localStorage.getItem('token');
    if (!token) return true;

    try {
        const decoded = jwtDecode(token);  // Изменено на jwtDecode с большой "D"
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
    } catch (e) {
        return true;
    }
}
const PrivateRoute = ({ children }) => {
    return isTokenExpired() ? <Navigate to="/lock-screen" /> : children;
};

export default PrivateRoute;
