import React, { useState, useEffect } from "react";
import axios from "axios";

export default function UploadWork() {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        category: "GIFs",
        price: "",
        startDate: "",
        endDate: "",
    });

    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null); // Для хранения ID пользователя

    // Получение текущего пользователя
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token"); // Получение токена
                if (token) {
                    const response = await axios.get("https://nifty-art.com/api/auth/user", {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setUserId(response.data.user._id); // Сохранение ID пользователя
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Обработчик выбора файла
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Создаем превью изображения
        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        reader.readAsDataURL(selectedFile);
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userId) {
            alert("User not authenticated. Please log in.");
            return;
        }
        setLoading(true);

        const data = new FormData();
        data.append("name", formData.name);
        data.append("description", formData.description);
        data.append("category", formData.category);
        data.append("price", formData.price);
        data.append("startDate", formData.startDate);
        data.append("endDate", formData.endDate);
        data.append("image", file);
        data.append("owner", userId); // Добавляем ID владельца

        try {
            const response = await axios.post("https://nifty-art.com/api/nfts/create", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("NFT created successfully!");
            console.log("Response:", response.data);
        } catch (error) {
            console.error("Error creating NFT:", error);
            alert("Failed to create NFT.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="container-fluid relative px-3">
                <div className="layout-specing">
                    <div className="container relative mt-6">
                        <div className="md:flex justify-center">
                            <div className="lg:w-4/5">
                                <div className="lg:flex p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800">
                                    <div className="lg:w-1/3 md:w-full">
                                        <p className="font-semibold mb-6">
                                            Upload your ART here, Please click "Upload Image" Button.
                                        </p>
                                        <div className="preview-box flex justify-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 dark:bg-slate-800 text-slate-400 p-2 text-center small">
                                            {preview ? (
                                                <img
                                                    src={preview}
                                                    alt="Preview"
                                                    className="rounded-md"
                                                    style={{ maxHeight: "200px" }}
                                                />
                                            ) : (
                                                "Supports JPG, PNG and MP4 videos. Max file size : 10MB."
                                            )}
                                        </div>
                                        <input
                                            type="file"
                                            id="input-file"
                                            name="image"
                                            accept="image/*"
                                            hidden
                                            onChange={handleFileChange}
                                        />
                                        <label
                                            htmlFor="input-file"
                                            className="btn-upload btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full mt-6 cursor-pointer"
                                        >
                                            Upload Image
                                        </label>
                                    </div>

                                    <div className="lg:w-2/3 md:w-full mt-8 lg:mt-0 lg:ms-6">
                                        <form onSubmit={handleSubmit}>
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12">
                                                    <label className="font-semibold">
                                                        Art Title <span className="text-red-600">*</span>
                                                    </label>
                                                    <input
                                                        name="name"
                                                        id="name"
                                                        type="text"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                        placeholder="Title :"
                                                    />
                                                </div>

                                                <div className="col-span-12">
                                                    <label className="font-semibold"> Description : </label>
                                                    <textarea
                                                        name="description"
                                                        id="comments"
                                                        value={formData.description}
                                                        onChange={handleChange}
                                                        className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                        placeholder="Description :"
                                                    ></textarea>
                                                </div>

                                                <div className="md:col-span-6 col-span-12">
                                                    <label className="font-semibold">Type :</label>
                                                    <select
                                                        name="category"
                                                        value={formData.category}
                                                        onChange={handleChange}
                                                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                    >
                                                        <option>GIFs</option>
                                                        <option>Music</option>
                                                        <option>Video</option>
                                                        <option>Tech</option>
                                                    </select>
                                                </div>

                                                <div className="md:col-span-6 col-span-12">
                                                    <label className="font-semibold"> Rate : </label>
                                                    <input
                                                        name="price"
                                                        type="number"
                                                        value={formData.price}
                                                        onChange={handleChange}
                                                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                        placeholder="0.004 ETH"
                                                    />
                                                </div>

                                                <div className="md:col-span-6 col-span-12">
                                                    <label className="font-semibold"> Starting Date : </label>
                                                    <input
                                                        name="startDate"
                                                        type="date"
                                                        value={formData.startDate}
                                                        onChange={handleChange}
                                                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                    />
                                                </div>

                                                <div className="md:col-span-6 col-span-12">
                                                    <label className="font-semibold"> Expiration date : </label>
                                                    <input
                                                        name="endDate"
                                                        type="date"
                                                        value={formData.endDate}
                                                        onChange={handleChange}
                                                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                    />
                                                </div>

                                                <div className="col-span-12">
                                                    <button
                                                        type="submit"
                                                        className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full"
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Creating..." : "Create Item"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
