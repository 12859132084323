import { Link, useNavigate } from 'react-router-dom';
import imagePlaceholder from '../../assets/images/client/05.jpg';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function LockScreen() {
    const [userData, setUserData] = useState(null);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Получаем userId из localStorage и запрашиваем данные пользователя
    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            navigate('/login');  // Перенаправляем на логин, если userId не найден
        } else {
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`https://nifty-art.com/api/users/change/${userId}`);
                    setUserData(response.data);
                } catch (error) {
                    console.error("Ошибка при получении данных пользователя:", error);
                    setError('Не удалось загрузить данные пользователя.');
                }
            };
            fetchUserData();
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userId = localStorage.getItem('userId');

        if (!userId || !password) {
            setError('Необходимо указать userId и пароль');
            return;
        }

        try {
            const response = await axios.post('https://nifty-art.com/api/auth/lockscreen-login', {
                userId,
                password,
            });

            if (response.data.success) {
                // Сохраняем токен в localStorage и очищаем ошибку
                localStorage.setItem('token', response.data.token);
                setError('');
                navigate('/');  // Переход на главную страницу
            } else {
                setError(response.data.message || 'Ошибка при входе');
            }
        } catch (err) {
            setError('Неправильный пароль. Попробуйте еще раз.');
            console.error('Ошибка при входе:', err);
        }
    };
    
    return (
        <>
            <section className="md:h-screen py-36 flex items-center bg-[url('../../assets/images/cta.jpg')] bg-no-repeat bg-center">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
                <div className="container">
                    <div className="flex justify-center">
                        <div className="max-w-[400px] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                            <div className="text-center">
                                <img src={userData?.avatar || imagePlaceholder} className="mx-auto w-28 rounded-full shadow dark:shadow-gray-800" alt="User Avatar" />
                                <h5 className="mb-6 mt-4 text-xl font-semibold">
                                    {userData ? `${userData.firstName} ${userData.lastName}` : "User"}
                                </h5>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-1">
                                    {error && <p className="text-red-500 mb-4">{error}</p>}
                                    <div className="mb-4">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                        <input
                                            id="LoginPassword"
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3"
                                            placeholder="Password:"
                                        />
                                    </div>
                                    <div className="flex justify-between mb-4">
                                        <div className="inline-flex items-center mb-0">
                                            <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe" />
                                            <label className="form-checkbox-label text-slate-400" htmlFor="RememberMe">Remember me</label>
                                        </div>
                                        <p className="text-slate-400 mb-0"><Link to="/reset-password" className="text-slate-400">Forgot password?</Link></p>
                                    </div>
                                    <div className="mb-4">
                                        <input type="submit" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full" value="Login / Sign in" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
