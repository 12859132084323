import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate,Link } from 'react-router-dom';
import image from '../../assets/images/avatar/1.jpg';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import metamask from '../../assets/images/wallet/metamask.png';
import { Facebook, Instagram, Linkedin, Youtube, Twitter } from 'react-feather';

export default function CreatorProfileEdit() {
    const { id } = useParams();  // Получение ID пользователя из URL
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        occupation: '',
        description: '',
        twitterURL: '',
        facebookURL: '',
        instagramURL: '',
        linkedinURL: '',
        youtubeURL: '',
        metamaskAddress: '', 
        websiteURL:  '', 
        phonenum: '',
        notifications: {
            mention: false,
            follow: false,
            shareActivity: false,
            message: false,
            salePromotion: false,
            companyNews: false,
            weeklyJobs: false,
            unsubscribeNews: false,
        },
    });
    const [isConnecting, setIsConnecting] = useState(false);

    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [avatarFile, setAvatarFile] = useState(null);

    const handleUploadAvatar = async () => {
        if (!avatarFile) {
            alert('Please select an image to upload.');
            return;
        }
    
        const formData = new FormData();
        formData.append('avatar', avatarFile); // Имя "avatar" должно совпадать с ожидаемым на сервере
    
        try {
            await axios.put(`https://nifty-art.com/api/users/${id}/upload-avatar`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            alert('Avatar updated successfully');
        } catch (error) {
            console.error('Error uploading avatar:', error);
            alert('Failed to upload avatar');
        }
    };
    
    // Загрузка данных пользователя при монтировании компонента
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://nifty-art.com/api/users/change/${id}`);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, [id]);

    // Обработка изменений в форме
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSavePassword = async (e) => {
        e.preventDefault();
        if (passwordData.newPassword !== passwordData.confirmNewPassword) {
            alert('New passwords do not match');
            return;
        }
        try {
            await axios.put(`https://nifty-art.com/api/users/${id}/change-password`, {
                oldPassword: passwordData.oldPassword,
                newPassword: passwordData.newPassword,
            });
            alert('Password updated successfully');
        } catch (error) {
            console.error('Error updating password:', error);
            alert('Failed to update password');
        }
    };
    // Обработка чекбоксов для уведомлений
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setUserData((prevState) => ({
            ...prevState,
            notifications: { ...prevState.notifications, [name]: checked }
        }));
    };
    // Сохранение уведомлений
    const handleSaveNotifications = async (e) => {
        e.preventDefault();
        try {
            // Отправляем данные уведомлений на сервер
            await axios.put(`https://nifty-art.com/api/users/${id}`, {
                notifications: userData.notifications,
            });
            alert('Notifications updated successfully');
        } catch (error) {
            console.error('Error updating notifications:', error);
            alert('Failed to update notifications');
        }
    };
    const handleSaveContactInfo = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://nifty-art.com/api/users/${id}`, {
                phone: userData.phone,
                website: userData.website,
            });
            alert('Contact info updated successfully');
        } catch (error) {
            console.error('Error updating contact info:', error);
            alert('Failed to update contact info');
        }
    };
    const connectMetaMask = async () => {
        if (!window.ethereum) {
            alert('MetaMask is not installed. Please install it and try again.');
            return;
        }
    
        setIsConnecting(true);
    
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const address = accounts[0];
    
            // Отправляем адрес MetaMask на сервер
            const response = await axios.post(`https://nifty-art.com/api/wallet/connect-metamask`, {
                userId: id,
                metamaskAddress: address,
            });
    
            if (response.data.success) {
                alert('MetaMask connected successfully!');
                setUserData((prevData) => ({
                    ...prevData,
                    metamaskAddress: address,
                }));
            }
        } catch (error) {
            console.error('Error connecting MetaMask:', error);
            if (error.response && error.response.status === 400) {
                alert(error.response.data.message || 'MetaMask address is already linked to another account');
            } else {
                alert('Failed to connect MetaMask.');
            }
        } finally {
            setIsConnecting(false);
        }
    };
    
    
    // Сохранение социальных профилей
    const handleSaveSocialProfiles = async (e) => {
        e.preventDefault();
        try {
            // Отправляем данные социальных профилей на сервер
            await axios.put(`https://nifty-art.com/api/users/${id}`, {
                twitterURL: userData.twitterURL,
                facebookURL: userData.facebookURL,
                instagramURL: userData.instagramURL,
                linkedinURL: userData.linkedinURL,
                youtubeURL: userData.youtubeURL,
            });
            alert('Social profiles updated successfully');
        } catch (error) {
            console.error('Error updating social profiles:', error);
            alert('Failed to update social profiles');
        }
    };


    // Сохранение изменений профиля
    const handleSaveChanges = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://nifty-art.com/api/users/${id}`, userData);
            alert('Profile updated successfully');
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to update profile');
        }
    };
    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete your account? This action is irreversible.');
        if (confirmDelete) {
            try {
                await axios.delete(`https://nifty-art.com/api/users/${id}`);
                alert('Account deleted successfully');
                navigate('/goodbye'); // Перенаправление на страницу после удаления или на страницу входа
            } catch (error) {
                console.error('Error deleting account:', error);
                alert('Failed to delete account');
            }
        }
    };
    const loadFile = (event) => {
        const image = document.getElementById(event.target.name);
        const file = event.target.files[0];
        if (file) {
            image.src = URL.createObjectURL(file);
            setAvatarFile(file); // Сохраняем файл в state
        }
    };
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">Edit Profile / Settings</h3>

                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">NIFTY</Link></li>
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Settings</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 gap-[30px]">
                        <div className="lg:col-span-3 md:col-span-4">
                            <div className="group profile-pic w-[112px]">
                                <input id="pro-img" name="profile-image" type="file" className="hidden" onChange={loadFile} />
                                <div>
                                    <div className="relative w-28 rounded-full shadow-md dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                                        <img src={userData.avatar || image} className="rounded-full" id="profile-image" alt="User Avatar" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                                        <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                    </div>
                                </div>
                            </div>

                            <p className="text-slate-400 mt-3">We recommend an image of at least 400X400. GIFs work too.</p>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary w-full"
                                    onClick={handleUploadAvatar}
                                >
                                    Save Avatar
                                </button>
                            </div>
                        </div>


                        <div className="lg:col-span-9 md:col-span-8">
                            <div className="flex flex-col items-center text-center py-8">
                                <h5 className="text-lg font-semibold mb-4">MetaMask Integration</h5>
                                <img
                                    src={metamask}
                                    className="bg-white dark:bg-slate-900 w-20 h-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3 mb-5"
                                    alt="MetaMask"
                                />
                                <div className="flex flex-col gap-4">
                                    {userData.metamaskAddress ? (
                                        <div className="text-green-600 font-medium">
                                            Connected MetaMask Address: {userData.metamaskAddress}
                                        </div>
                                    ) : (
                                        <button
                                            onClick={connectMetaMask}
                                            className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full"
                                            disabled={isConnecting}
                                        >
                                            {isConnecting ? 'Connecting...' : 'Connect MetaMask'}
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                <h5 className="text-lg font-semibold mb-4">Personal Detail :</h5>
                                <form onSubmit={handleSaveChanges}>
                                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                                        <div>
                                            <label className="form-label font-medium">First Name : <span className="text-red-600">*</span></label>
                                            <input type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="First Name:" id="firstname" value={userData.firstName} onChange={handleChange} name="firstName" />
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Last Name : <span className="text-red-600">*</span></label>
                                            <input type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Last Name:" id="lastname" value={userData.lastName} onChange={handleChange} name="lastName" />
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Your Email : <span className="text-red-600">*</span></label>
                                            <input type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Email" value={userData.email} onChange={handleChange} name="email" />
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Occupation : </label>
                                            <input name="occupation" id="occupation" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Occupation :" value={userData.occupation} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1">
                                        <div className="mt-5">
                                            <label className="form-label font-medium">Description : </label>
                                            <textarea name="description" id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Message :" value={userData.description} onChange={handleChange}></textarea>
                                        </div>
                                    </div>

                                    <input type="submit" id="submit" name="send" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5" value="Save Changes" />
                                </form>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-6">Social Profiles :</h5>
                                <form onSubmit={handleSaveSocialProfiles}>
                                    <div className="md:flex">
                                        <div className="md:w-1/3">
                                            <span className="font-medium">Twitter</span>
                                        </div>

                                        <div className="md:w-2/3 mt-4 md:mt-0">
                                            <form>
                                                <div className="form-icon relative">
                                                    <Twitter className="size-4 absolute top-3 start-4"></Twitter>
                                                    <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Twitter Profile Name" id="twitter_name" value={userData.twitterURL} onChange={handleChange} name="twitterURL" />
                                                </div>
                                            </form>

                                            <p className="text-slate-400 mt-1">Add your Twitter username (e.g. jennyhot).</p>
                                        </div>
                                    </div>

                                    <div className="md:flex mt-8">
                                        <div className="md:w-1/3">
                                            <span className="font-medium">Facebook</span>
                                        </div>

                                        <div className="md:w-2/3 mt-4 md:mt-0">
                                            <form>
                                                <div className="form-icon relative">
                                                    <Facebook className="size-4 absolute top-3 start-4"></Facebook>
                                                    <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Facebook Profile Name" id="facebook_name" value={userData.facebookURL} onChange={handleChange} name="facebookURL" />
                                                </div>
                                            </form>

                                            <p className="text-slate-400 mt-1">Add your Facebook username (e.g. jennyhot).</p>
                                        </div>
                                    </div>

                                    <div className="md:flex mt-8">
                                        <div className="md:w-1/3">
                                            <span className="font-medium">Instagram</span>
                                        </div>

                                        <div className="md:w-2/3 mt-4 md:mt-0">
                                            <form>
                                                <div className="form-icon relative">
                                                    <Instagram className="size-4 absolute top-3 start-4"></Instagram>
                                                    <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Instagram Profile Name" id="insta_name" value={userData.instagramURL} onChange={handleChange} name="instagramURL" />
                                                </div>
                                            </form>

                                            <p className="text-slate-400 mt-1">Add your Instagram username (e.g. jennyhot).</p>
                                        </div>
                                    </div>

                                    <div className="md:flex mt-8">
                                        <div className="md:w-1/3">
                                            <span className="font-medium">Linkedin</span>
                                        </div>

                                        <div className="md:w-2/3 mt-4 md:mt-0">
                                            <form>
                                                <div className="form-icon relative">
                                                    <Linkedin className="size-4 absolute top-3 start-4"></Linkedin>
                                                    <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Linkedin Profile Name" id="linkedin_name"value={userData.linkedinURL} onChange={handleChange} name="linkedinURL" />
                                                </div>
                                            </form>

                                            <p className="text-slate-400 mt-1">Add your Linkedin username.</p>
                                        </div>
                                    </div>

                                    <div className="md:flex mt-8">
                                        <div className="md:w-1/3">
                                            <span className="font-medium">Youtube</span>
                                        </div>

                                        <div className="md:w-2/3 mt-4 md:mt-0">
                                            <form>
                                                <div className="form-icon relative">
                                                    <Youtube className="size-4 absolute top-3 start-4"></Youtube>
                                                    <input type="url" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" placeholder="Youtube url" id="you_url" value={userData.youtubeURL} onChange={handleChange} name="youtubeURL" />
                                                </div>
                                            </form>

                                            <p className="text-slate-400 mt-1">Add your Youtube url.</p>
                                        </div>
                                    </div>

                                    <div className="md:flex">
                                        <div className="md:w-full">
                                            <button
                                                type="submit"
                                                className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5"
                                            >
                                                Save Social Profile
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-6">Account Notifications :</h5>
                                <form onSubmit={handleSaveNotifications}>
                                    <div className="flex justify-between pb-4">
                                        <h6 className="mb-0 font-medium">When someone mentions me</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti1"
                                                checked={userData.notifications.mention}
                                                onChange={handleCheckboxChange}
                                                name="mention"
                                            />
                                            <label className="form-check-label" htmlFor="noti1"></label>
                                        </div>
                                    </div>

                                    <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                        <h6 className="mb-0 font-medium">When someone follows me</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti2"
                                                checked={userData.notifications.follow}
                                                onChange={handleCheckboxChange}
                                                name="follow"
                                            />
                                            <label className="form-check-label" htmlFor="noti2"></label>
                                        </div>
                                    </div>

                                    <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                        <h6 className="mb-0 font-medium">When shares my activity</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti3"
                                                checked={userData.notifications.shareActivity}
                                                onChange={handleCheckboxChange}
                                                name="shareActivity"
                                            />
                                            <label className="form-check-label" htmlFor="noti3"></label>
                                        </div>
                                    </div>

                                    <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                        <h6 className="mb-0 font-medium">When someone messages me</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti4"
                                                checked={userData.notifications.message}
                                                onChange={handleCheckboxChange}
                                                name="message"
                                            />
                                            <label className="form-check-label" htmlFor="noti4"></label>
                                        </div>
                                    </div>
                                    <div className="md:flex">
                                        <div className="md:w-full">
                                            <button
                                                type="submit"
                                                className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5"
                                            >
                                                Save Notifications
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-6">Marketing Notifications :</h5>
                                <form onSubmit={handleSaveNotifications}>
                                    <div className="flex justify-between pb-4">
                                        <h6 className="mb-0 font-medium">There is a sale or promotion</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti5"
                                                checked={userData.notifications.salePromotion}
                                                onChange={handleCheckboxChange}
                                                name="salePromotion"
                                            />
                                            <label className="form-check-label" htmlFor="noti5"></label>
                                        </div>
                                    </div>

                                    <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                        <h6 className="mb-0 font-medium">Company news</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti6"
                                                checked={userData.notifications.companyNews}
                                                onChange={handleCheckboxChange}
                                                name="companyNews"
                                            />
                                            <label className="form-check-label" htmlFor="noti6"></label>
                                        </div>
                                    </div>

                                    <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                        <h6 className="mb-0 font-medium">Weekly jobs</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti7"
                                                checked={userData.notifications.weeklyJobs}
                                                onChange={handleCheckboxChange}
                                                name="weeklyJobs"
                                            />
                                            <label className="form-check-label" htmlFor="noti7"></label>
                                        </div>
                                    </div>

                                    <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                        <h6 className="mb-0 font-medium">Unsubscribe News</h6>
                                        <div>
                                            <input
                                                className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50"
                                                type="checkbox"
                                                value=""
                                                id="noti8"
                                                checked={userData.notifications.unsubscribeNews}
                                                onChange={handleCheckboxChange}
                                                name="unsubscribeNews"
                                            />
                                            <label className="form-check-label" htmlFor="noti8"></label>
                                        </div>
                                    </div>
                                    <div className="md:flex">
                                        <div className="md:w-full">
                                            <button
                                                type="submit"
                                                className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5"
                                            >
                                                Save Notifications
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-5">Contact Info :</h5>
                                <form onSubmit={handleSaveContactInfo}>
                                    <div className="grid grid-cols-1 gap-5">
                                        <div>
                                            <label className="form-label font-medium">Phone No. :</label>
                                            <input
                                                name="phonenum"
                                                type="text"
                                                className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                placeholder="Phone"
                                                value={userData.phonenum}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Website :</label>
                                            <input
                                                name="website"
                                                type="url"
                                                className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                placeholder="Url"
                                                value={userData.websiteURL}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5">Save Contact Info</button>
                                </form>
                            </div>


                             {/* Change Password Section */}
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-5">Change password :</h5>
                                <form onSubmit={handleSavePassword}>
                                    <div className="grid grid-cols-1 gap-5">
                                        <div>
                                            <label className="form-label font-medium">Old password :</label>
                                            <input
                                                type="password"
                                                name="oldPassword"
                                                className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                placeholder="Old password"
                                                value={passwordData.oldPassword}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">New password :</label>
                                            <input
                                                type="password"
                                                name="newPassword"
                                                className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                placeholder="New password"
                                                value={passwordData.newPassword}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                        <div>
                                            <label className="form-label font-medium">Re-type New password :</label>
                                            <input
                                                type="password"
                                                name="confirmNewPassword"
                                                className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                placeholder="Re-type New password"
                                                value={passwordData.confirmNewPassword}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                    </div>
                                    <button type="submit" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5">Save password</button>
                                </form>
                            </div>


                            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                                <h5 className="text-lg font-semibold mb-5 text-red-600">Delete Account :</h5>
                                <p className="text-slate-400 mb-4">Do you want to delete the account? This action is irreversible.</p>
                                <button
                                    onClick={handleDeleteAccount}
                                    className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-full"
                                >
                                    Delete Account
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
