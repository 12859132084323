import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';


export default function ItemDetail() {
    const { id , username } = useParams(); // Получаем ID из URL
    const [nft, setNft] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buyNow, setBuyNow] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [price, setPrice] = useState(''); // Цена для установки

    useEffect(() => {
        // Загружаем данные текущего пользователя
        const fetchCurrentUser = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const userResponse = await axios.get('https://nifty-art.com/api/auth/user', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setCurrentUser(userResponse.data);
                }
            } catch (error) {
                console.error("Error fetching current user:", error);
            }
        };

        const fetchNFT = async () => {
            try {
                const response = await axios.get(`https://nifty-art.com/api/nfts/${username}/${id}`);
                setNft(response.data);
                setPrice(response.data.price);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching NFT data:", error.response?.data || error.message);
                setLoading(false);
            }
        };
        
        fetchCurrentUser();
        fetchNFT();
    }, [id]);
    const handleSetPrice = async () => {
        try {
            const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
            const response = await axios.put(
                `https://nifty-art.com/api/nfts/${id}/set-price`,
                {
                    price,
                    forSale: true,
                    name: nft.name, // Передаем данные о NFT
                    description: nft.description,
                    image: nft.image,
                    contract: nft.contract,
                    category: nft.category,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Добавляем токен авторизации в заголовки
                    },
                }
            );
            setNft({ ...nft, price: response.data.nft.price, forSale: true });
        } catch (error) {
            console.error('Error setting price:', error.response?.data || error.message);
        }
    };
    
    const handleRemoveFromSale = async () => {
        try {
            const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
            const response = await axios.put(
                `https://nifty-art.com/api/nfts/${id}/remove-from-sale`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Добавляем токен авторизации в заголовки
                    },
                }
            );
            setNft({ ...nft, forSale: false });
        } catch (error) {
            console.error('Error removing NFT from sale:', error.response?.data || error.message);
        }
    };
    
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img 
                    src={require('../../assets/images/loading.gif')} 
                    alt="Loading..." 
                    className="w-10000 h-10000"
                />
            </div>
        );
    }
    
    if (!nft) return <p>NFT not found</p>;

    const isOwner = currentUser?.user?.username === nft.owner?.username;
    
    return (
        <>
            <Navbar />
            <section className="relative pt-28 md:pb-24 pb-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5">
                            <img
                                src={nft.image.startsWith('/uploads/nfts/')
                                    ? `https://nifty-art.com${nft.image}`
                                    : nft.image}
                                className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500"
                                alt={nft.name}
                            />

                            <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                                <div>
                                    <span className="font-medium text-slate-400 block mb-1">Contract Address</span>
                                    <Link to="#" className="font-medium text-violet-600 underline block"> {nft.contract  || nft.contract || "1fsvtgju51ntgeryimghf6ty7o9n3r3er246"}</Link>
                                </div>
                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Token ID</span>
                                    <span className="font-medium block">
                                        {nft._id || nft.id
                                            ? `${(nft._id || nft.id).slice(0, 5)}...${(nft._id || nft.id).slice(-5)}`
                                            : 'Unavailable'}
                                    </span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Blockchain</span>
                                    <span className="font-medium block">ETH</span>
                                </div>
                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Deposit & Withdraw</span>
                                    <span className="font-medium block">Unsupported</span>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 lg:ms-8">
                            <h5 className="md:text-2xl text-xl font-semibold">{nft.name}</h5>
                            <span className="font-medium text-slate-400 block mt-2">
                                From this collection: <Link to={`/creator-profile/${nft.owner.username}`} className="text-violet-600">{nft.owner.username}</Link>
                            </span>
                            <p className="text-slate-400 mt-4">{nft.description}</p>

                            <div className="mt-4">
                                
                                    {isOwner ? (

                                    <div className="mt-4">
                                        
                                        {nft.forSale ? (
                                            <>
                                                <span className="text-lg font-medium text-slate-400 block">Current Price</span>
                                                <span className="md:text-2xl text-xl font-semibold block mt-2"><i className="mdi mdi-ethereum"></i> {nft.price} €</span>
                                                <button
                                                    onClick={handleRemoveFromSale}
                                                    className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-full mt-4"
                                                >
                                                    Remove from Sale
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <div className="mt-4 bg-slate-800 p-4 rounded-md shadow-md">
                                                    <span className="text-lg font-medium text-slate-400 block">Set Price</span>
                                                    <input
                                                        type="number"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        className="border rounded-md px-3 py-2 mt-2 w-1/2 bg-slate-900 text-white focus:outline-none focus:ring-2 focus:ring-violet-600"
                                                        placeholder="Enter price in €"
                                                    />
                                                </div>
                                                <button
                                                    onClick={handleSetPrice}
                                                    className="btn bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white rounded-full mt-4"
                                                >
                                                    Set for Sale
                                                </button>
                                            </>
                                        )}

                                    </div>
                                ) : (
                                    <div className="mt-4">
                                        {nft.forSale ? (
                                            <>
                                                <span className="text-lg font-medium text-slate-400 block">Market Price</span>
                                                <span className="md:text-2xl text-xl font-semibold block mt-2"><i className="mdi mdi-ethereum"></i> {nft.price} €</span>
                                                <Link
                                                    to="#"
                                                    className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-4"
                                                >
                                                    Buy Now
                                                </Link>
                                            </>
                                        ) : (
                                            <span className="text-lg font-medium text-red-600 block">NOT FOR SALE</span>
                                        )}
                                    </div>
                                )}
                            </div>
                            
                            <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                <div className="md:w-1/2">
                                    <div className="flex items-center">
                                        <img src={`https://nifty-art.com${nft.owner.avatar}`} className="h-16 rounded-md" alt={nft.owner.firstName} />
                                        <div className="ms-3">
                                            <Link to={`/creator-profile/${nft.owner.username}`} className="font-semibold block hover:text-violet-600">{nft.owner.firstName} {nft.owner.lastName}</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Owner</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    );
}
