import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Select from 'react-select';
import DiscoverItems from '../../components/discover-items';
import axios from 'axios';
import { LuClock, LuSearch } from 'react-icons/lu';
import { AiOutlineAppstore, AiOutlineStar } from 'react-icons/ai';

// Опции для фильтров
const options = [
    { value: '', label: 'All' },
    { value: 'Art', label: 'Art' },
    { value: 'Domain', label: 'Domain Names' },
    { value: 'Music', label: 'Music' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Trading', label: 'Trading Cards' },
    { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
    { value: '', label: 'All' },
    { value: 'now', label: 'Buy Now' },
    { value: 'auctions', label: 'Auctions' },
    { value: 'offers', label: 'Offers' },
];

const rateOptions = [
    { value: '', label: 'All' },
    { value: 'top', label: 'Top Rated' },
    { value: 'low', label: 'Lower Rated' },
];

// Настройка стилей для Select
const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#1e293b',
        borderColor: '#1e40af',
        color: '#ffffff',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#1e40af' : '#1e293b',
        color: state.isFocused ? '#ffffff' : '#94a3b8',
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#ffffff',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#1e293b',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#94a3b8',
    }),
};

export default function ExploreOne() {
    const [search, setSearch] = useState('');
    const [category, setCategory] = useState(options[0]);
    const [buyOption, setBuyOption] = useState(buyOptions[0]);
    const [rateOption, setRateOption] = useState(rateOptions[0]);
    const [nfts, setNfts] = useState([]);

    useEffect(() => {
        const fetchNFTsWithFilters = async () => {
            try {
                const params = {
                    search: search || undefined,
                    category: category?.value || undefined,
                    buyOption: buyOption?.value || undefined,
                    rateOption: rateOption?.value || undefined,
                };

                const response = await axios.get('https://nifty-art.com/api/nfts/market', { params });
                const nftsWithOwners = await Promise.all(
                    response.data.map(async nft => {
                        try {
                            const ownerResponse = await axios.get(`https://nifty-art.com/api/users/change/${nft.owner}`);
                            return {
                                ...nft,
                                ownerUsername: ownerResponse.data.username,
                                ownerAvatar: ownerResponse.data.avatar || '/assets/images/avatar/2.jpg',
                            };
                        } catch (error) {
                            console.error(`Error fetching owner data for NFT ${nft._id}:`, error);
                            return {
                                ...nft,
                                ownerUsername: 'Unknown Owner',
                                ownerAvatar: '/assets/images/avatar/2.jpg',
                            };
                        }
                    })
                );
                setNfts(nftsWithOwners);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
            }
        };

        fetchNFTsWithFilters();
    }, [search, category, buyOption, rateOption]);

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
                            Explore Items
                        </h3>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:pb-24 pb-16 -mt-16">
                <div className="container z-1">
                    <div className="grid grid-cols-1">
                        <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
                            <div className="registration-form text-dark text-start">
                                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <label className="form-label font-medium dark:text-white">Search:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuSearch className="icons" />
                                            <input
                                                name="name"
                                                type="text"
                                                id="job-keyword"
                                                className="form-input w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-transparent"
                                                placeholder="Search your keywords"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-catagory" className="form-label font-medium dark:text-white">Categories:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <AiOutlineAppstore className="icons" />
                                            <Select
                                                className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                                options={options}
                                                value={category}
                                                onChange={setCategory}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-min-price" className="form-label font-medium dark:text-white">Items:</label>
                                        <div className="filter-search-form relative filter-border mt-2">
                                            <LuClock className="icons text-base" />
                                            <Select
                                                className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                                options={buyOptions}
                                                value={buyOption}
                                                onChange={setBuyOption}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="choices-max-price" className="form-label font-medium dark:text-white">Sort By:</label>
                                        <div className="filter-search-form relative mt-2">
                                            <AiOutlineStar className="icons" />
                                            <Select
                                                className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                                options={rateOptions}
                                                value={rateOption}
                                                onChange={setRateOption}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <DiscoverItems nfts={nfts} pagination={true} title="Discover Items" />
            </section>
            <Footer />
        </>
    );
}
