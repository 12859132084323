import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineArrowForward } from 'react-icons/md';

export default function DiscoverItems({ title, pagination, allData, nfts, isOwnProfile }) {
    const [marketNfts, setMarketNfts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8; // Количество элементов на страницу

    useEffect(() => {
        const fetchMarketNFTs = async () => {
            try {
                const response = await axios.get('https://nifty-art.com/api/nfts/market');
                const nftsWithOwners = await Promise.all(response.data.map(async nft => {
                    try {
                        const ownerResponse = await axios.get(`https://nifty-art.com/api/users/change/${nft.owner}`);
                    
                        return { 
                            ...nft, 
                            ownerUsername: ownerResponse.data.username, 
                            ownerAvatar: ownerResponse.data.avatar || '/assets/images/avatar/2.jpg' 
                        };
                        
                    } catch (error) {
                        console.error(`Error fetching owner data for NFT ${nft._id}:`, error);
                        return { 
                            ...nft, 
                            ownerUsername: 'Unknown Owner', 
                            ownerAvatar: '/assets/images/avatar/2.jpg' 
                        };
                    }
                }));
                setMarketNfts(nftsWithOwners);
            } catch (error) {
                console.error("Error fetching market NFTs:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMarketNFTs();
    }, []);

    const itemsToDisplay = nfts || marketNfts;

    // Вычисляем элементы для текущей страницы
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = itemsToDisplay.slice(startIndex, endIndex);

    // Управление страницами
    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (endIndex < itemsToDisplay.length) setCurrentPage(currentPage + 1);
    };

    return (
        <div className="container">
            <div className="grid grid-cols-1 text-center">
                <h3 className="md:text-[30px] text-[26px] font-semibold">{title}</h3>
            </div>

            {loading ? (
                <p className="text-center text-slate-400 mt-10">Loading NFTs...</p>
            ) : currentItems.length > 0 ? (
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">
                    {currentItems.map((item, index) => (
                        <div key={index} className="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                            <div className="relative overflow-hidden">
                                <div className="relative overflow-hidden rounded-lg">
                                    <img
                                        src={item.image?.startsWith('/uploads/nfts/')
                                            ? `https://nifty-art.com${item.image}`
                                            : item.image || item.display_image_url || 'https://via.placeholder.com/150'}
                                        className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500"
                                        alt={item.name}
                                    />

                                </div>

                                {!isOwnProfile && (
                                    <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                        <Link to={`/item-detail/${item.ownerUsername}/${item._id || item.id }`} className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white">
                                            <i className="mdi mdi-lightning-bolt"></i> Buy Now
                                        </Link>
                                    </div>
                                )}
                            </div>

                            <div className="mt-3">
                                <div className="flex items-center">
                                    <div className="relative inline-block">
                                        <img src={item.ownerAvatar} className="rounded-full size-8" alt={item.ownerUsername} />
                                        <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                    </div>
                                    <Link to={`/creator-profile/${item.ownerUsername}`} className="ms-2 text-[15px] font-medium text-slate-400 hover:text-violet-600">
                                        {item.ownerUsername}
                                    </Link>
                                </div>

                                <div className="my-3">
                                    <Link to={`/item-detail/${item.ownerUsername}/${item._id || item.id}`} className="font-semibold hover:text-violet-600">{item.name}</Link>
                                </div>

                                <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                    <div>
                                        <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                        <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> {item.price} €</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-center text-slate-400 mt-10">No NFTs available</p>
            )}

            {pagination && (
                <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                    <div className="md:col-span-12 text-center">
                        <nav>
                            <ul className="inline-flex items-center -space-x-px">
                                <li>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                                    >
                                        <MdKeyboardArrowLeft className="text-[20px]" />
                                    </button>
                                </li>
                                <li>
                                    <span className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700">
                                      {currentPage}
                                    </span>
                                </li>
                                <li>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={endIndex >= itemsToDisplay.length}
                                        className="size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                                    >
                                        <MdKeyboardArrowRight className="text-[20px]" />
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}

            {title && (
                <div className="grid grid-cols-1 mt-6">
                    <div className="text-center">
                        <Link to="/explore-one" className="btn btn-link text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">
                            Explore More <MdOutlineArrowForward className="ms-1 text-sm" />
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}
