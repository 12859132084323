import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import single from '../../assets/images/blog/single.jpg';
import image from '../../assets/images/avatar/1.jpg';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import DiscoverItems from '../../components/discover-items';
import UploadWork from './upload-work'; // Импорт компонента UploadWork

export default function CreatorProfile() {
    const { username } = useParams();  // Username from URL
    const [creator, setCreator] = useState(null);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null); // Logged-in user
    const [nfts, setNfts] = useState([]); // NFT list for the profile

    const fetchNFTsWithOwners = async (nfts) => {
        return Promise.all(
            nfts.map(async (nft) => {
                try {
                    const ownerResponse = await axios.get(`https://nifty-art.com/api/users/change/${nft.owner}`);
                    return {
                        ...nft,
                        ownerUsername: ownerResponse.data.username,
                        ownerAvatar: ownerResponse.data.avatar || '/assets/images/avatar/2.jpg',
                    };
                } catch (error) {
                    console.error(`Error fetching owner data for NFT ${nft._id}:`, error);
                    return {
                        ...nft,
                        ownerUsername: 'Unknown Owner',
                        ownerAvatar: '/assets/images/avatar/2.jpg',
                    };
                }
            })
        );
    };

    useEffect(() => {
        // Fetch creator data
        const fetchCreatorData = async () => {
            try {
                if (!username) {
                    console.error("Username is undefined or empty");
                    setLoading(false);
                    return;
                }
        
                // Запрос данных пользователя
                const response = await axios.get(`https://nifty-art.com/api/users/profile/${username}`);
                setCreator(response.data);
        
                // Запрос данных о NFT
                const nftResponse = await axios.get(`https://nifty-art.com/api/nfts/user/${username}`);
                console.log("NFT Response:", nftResponse.data);
        
                const nftData = nftResponse.data.nfts || []; // Извлекаем массив `nfts` из ответа
        
                if (!Array.isArray(nftData)) {
                    console.error("NFT data is not an array:", nftResponse.data);
                    setLoading(false);
                    return;
                }
        
                // Обрабатываем NFT
                const nftsWithOwners = await fetchNFTsWithOwners(nftData);
                setNfts(nftsWithOwners);
        
                setLoading(false);
            } catch (error) {
                console.error("Error fetching creator data or NFTs:", {
                    message: error.message,
                    stack: error.stack,
                    response: error.response?.data || "No response data",
                });
                setLoading(false);
            }
        };
        

        // Fetch logged-in user data
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
                if (token) {
                    const userResponse = await axios.get('https://nifty-art.com/api/auth/user', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUser(userResponse.data);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchCreatorData();
        fetchUserData();
    }, [username]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img 
                    src={require('../../assets/images/loading.gif')} 
                    alt="Loading..." 
                    className="w-10000 h-10000"
                />
            </div>
        );
    }
    

    const isOwnProfile = user?.user?.username === creator?.username;

    return (
        <>
            <Navbar />

            <section className="relative md:pb-24 pb-16 lg:mt-24 mt-[74px]">
                <div className="lg:container container-fluid">
                    <div className="group profile-banner relative overflow-hidden text-transparent lg:rounded-xl shadow dark:shadow-gray-700">
                        <div className="relative shrink-0">
                            <img src={single} className="h-80 w-full object-cover" id="profile-banner" alt="" />
                            <div className="absolute inset-0 bg-slate/10 group-hover:bg-slate-900/40 transition duration-500" />
                        </div>
                    </div>
                </div>

                <div className="md:flex justify-center">
                    <div className="md:w-full">
                        <div className="relative -mt-[60px] text-center">
                            <div className="group profile-pic w-[112px] mx-auto">
                                <div>
                                    <div className="relative w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                                        <img src={creator?.avatar || image} className="rounded-full" id="profile-image" alt="" />
                                        <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6">
                                <h5 className="text-xl font-semibold">
                                    {creator ? `${creator.firstName} ${creator.lastName}` : "Jenny Jimenez"}
                                    <i className="mdi mdi-check-decagram text-emerald-600 align-middle text-lg"></i>
                                </h5>
                                <p className="text-slate-400 text-[16px] mt-1">
                                    Created by{" "}
                                    <Link to={`/creator-profile/${creator.username}`} className="text-violet-600 font-semibold">
                                        {creator?.username || "1x5484dcdvcdscds56c4"}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                

                {/* DiscoverItems component to display NFTs of the creator */}
                <DiscoverItems nfts={nfts} pagination={true} title="Discover Items" />
                {/* Show UploadWork component if the profile belongs to the logged-in user */}
                {/*{isOwnProfile && (
                    <section className="mt-10">
                        <h3 className="text-center text-xl font-semibold mb-4">Upload Your Work</h3>
                        <UploadWork />
                    </section>
                )}*/}
            </section>

            <Footer />
        </>
    );
}
